import React from 'react';
import clsx from 'clsx';
import LazyImg from 'components/image/LazyImg';
import RiceSpinner from 'components/spinner/RiceSpinner';
import makeStyles from './ProductImages.css.js';

// expect images to be in ShopifyBuy product payload shape 
const ProductImages = ({ images }) => {

    const [stage, setStage] = React.useState(0);

    // determine image source (cdn or local)
    const getSource = React.useCallback((img) => {
        // via ShopifyBuy API
        return img.src;
        // via Shopify CDN
        // return img.originalSrc;
        // via gatsby local file server
        // return img.localFile.publicURL
    });

    const classes = makeStyles();

    return (
        <div className={classes.ProductImages}>
            <LazyImg className={classes.stage} src={getSource(images[stage])} imgProps={{className: classes.stageImg}}>
                <RiceSpinner size="8rem" />
            </LazyImg>
            {images.length > 1 && (
                <div className={classes.bench}>
                    {images.map((img, i) => {
                        const isActive = i === stage;
                        const handleClick = isActive ? null : () => setStage(i);
                        return (
                                <LazyImg
                                    key={i}
                                    onClick={handleClick}
                                    src={getSource(img)}
                                    wrapperProps={{
                                        className: clsx({
                                            [classes.benchImage]: true,
                                            [classes.benchActive]: isActive
                                        }),
                                    }}
                                >
                                    <RiceSpinner size="3rem" />
                                </LazyImg>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default ProductImages;