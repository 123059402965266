import React from 'react';
import useStyles from './ShopAlert.css';

function alertIcon(alertType){
    switch(alertType) {
        default:
            return (
                <svg width="1em" height="1em" stroke="none" fill="currentColor" viewBox="-2 -2 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin">
                    <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-13a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1zm0 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
            );
    }
}

export default function({ type, children }) {

    const cls = useStyles();

    return (
        <div className={cls.ShopAlert}>
            <div className={cls.icon}>
                {alertIcon(type)}
            </div>
            <div className={cls.message}>
                {children}
            </div>
        </div>
    );
}