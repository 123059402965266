import React from 'react';
import { navigate } from 'gatsby';
import { shopIsOnline } from 'components/context/ShopStatus';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import ShopProduct from 'components/shop/ShopProduct';
import { Box } from '@material-ui/core';

const ShopProductPage = ({ location }) => {

    // get product handle from query parameters
    const productHandle = new URLSearchParams(location.search).get('h');
    
    if (typeof window !== 'undefined') {
        // redirect to shop if shop status is not valid
        if (!shopIsOnline() || !productHandle) {
            navigate("/shop");
        }
    }

    return (
        <Layout containerSize="lg" enableCart>
            <PageTitle navOffset></PageTitle>
            <Box pb={16}>
                <ShopProduct handle={productHandle} />
            </Box>
        </Layout>
    );
}

export default ShopProductPage;