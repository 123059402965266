import React from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import makeStyles from './ProductDetail.css.js';

/*
    Collapsible product detail section
        label: toggler text
        children: collapse content
*/
const ProductDetail = ({ id, label, children }) => {

    const [open, setOpen] = React.useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    }

    const classes = makeStyles();

    return (
        <div id={id} className={classes.ProductDetail}>
            <button className={clsx({
                [classes.control]: true,
                [classes.controlOpen]: open,
            })} onClick={toggleOpen}>
                <span className={classes.label}>{label}</span>
                <span className={classes.controlArrow} />
            </button>
            <Collapse
                in={open}
                timeout={300}
                classes={{ container: classes.collapseContainer }}
            >
                <div className={classes.content}>
                    {children}
                </div>
            </Collapse>
        </div>
    );
}

export default ProductDetail;