import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
    return {
        AddToCartForm: {
            margin: theme.spacing(2,0,0),
            '& > *': {
                margin: theme.spacing(0,0,2),
            },
        }
    };
});