import React from 'react';
import { Box } from '@material-ui/core';
import { useShopifyBuy } from 'components/context/ShopifyBuy';
import makeStyles from './ProductMetrics.css.js';

const ProductMetrics = ({ product }) => {

    const classes = makeStyles();
    const price = product.variants[0].priceV2;

    return (
        <div className={classes.ProductMetrics}>
            <div className={classes.metric}>
                {Math.floor(price.amount)}
                {` `}
                <Box component="span" color="secondary.dark">
                    {price.currencyCode}
                </Box>
            </div>
            <div className={classes.metric}>
                {!product.availableForSale && (
                    <Box color="#BD2000">
                        SOLD OUT
                    </Box>
                )}
            </div>
        </div>
    );
}

export default ProductMetrics;