import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {

    return {
        productPageNav: {
            position: 'sticky',
            top: 0,
            display: 'block',
            fontSize: 16,
            padding: theme.spacing(1,0),
            margin: theme.spacing(1,0),
            backgroundColor: theme.palette.background.default,
            zIndex: 2,
        },
        navCrumb: {
            display: 'inline-block',
            fontSize: 'inherit',
            lineHeight: '2.5em',
            margin: 0,
            textTransform: 'uppercase',
            '&:after': {
                content: '"/"',
                opacity: 0.4,
                margin: theme.spacing(0, 1.5),
            },
            '&:last-of-type': {
                opacity: 0.4,
                '&:after': {
                    content: 'none',
                }
            }
        },
        productPageContainer: {
            position: 'relative',
            zIndex: 1,
        },
        ShopProduct: {
            display: 'block',
            padding: theme.spacing(2, 0),
            fontSize: 16,
            position: 'relative',
            padding: theme.spacing(8, 0),
            [theme.breakpoints.up('sm')]: {
                fontSize: 18,
                padding: theme.spacing(8, 12),
            },
        },
        productImagesContainer: {
            display: 'block',
            textAlign: 'center',
        },
        productTitle: {
            fontWeight: 'bold',
            fontSize: 20,
            textTransform: 'uppercase',
            [theme.breakpoints.up('sm')]: {
                fontSize: 24,
            }
        },
        productDescription: {
            // Shopify description formatting
            '& p': {
                margin: 0,
            },
            // '& p': {
            //     margin: theme.spacing(0,0,2),
            //     // add margin under second child (end of metrics)
            //     '&:nth-child(n+3)': {
            //         marginBottom: 0,
            //     },
            //     // last child footer
            //     '&:last-of-type': {
            //         marginTop: theme.spacing(2),
            //     },
            // },
            // '& br': {
            //     display: 'none',
            // },
        },
        notice: {
            color: theme.palette.primary.dark,
            background: theme.palette.secondary.light.replace('rgb(', 'rgba(').replace(')', ', 0.4)'),
            borderRadius: 3,
            padding: theme.spacing(2),
        }
    };
});