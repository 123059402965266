import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    LazyImg: {
        position: 'relative',
    },
    img: ({ done }) => ({
        opacity: done ? 1 : 0,
        transition: 'opacity 150ms',
    }),
    loader: {
        fontSize: 12,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
    },
    loaderContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}));