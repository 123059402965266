import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
    return {
        ShopAlert: {
            fontSize: '1rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.grey[100],
            margin: theme.spacing(4, 0),
        },
        icon: {
            fontSize: '3em',
            height: '1em',
            width: '1em',
            color: theme.palette.primary.main,
            margin: theme.spacing(0, 2, 0, 0),
            padding: 0,
        },
        message: {
            flexGrow: 1,
            '& a': {
                color: theme.palette.primary.dark,
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': {
                    color: theme.palette.primary.light,
                }
            }
        },
    };
});