import React from 'react';
import RiceSpinner from 'components/spinner/RiceSpinner';
import useStyles from './LazyImg.css';

// children = loader
const LazyImg = ({ src, children, wrapperProps, imgProps, className, ...props }) => {
    
    const ref = React.useRef();
    const [done, setDone] = React.useState(done);

    // init lazy image handling
    React.useEffect(() => {
        if (ref.current.complete) {
            setDone(true);
        } else {
            ref.current.onload = () => {
                setDone(true);
            }
        }
    }, []);

    const cls = useStyles({ done });

    return (
        <div className={`${cls.LazyImg} ${className || ''}`} {...props}>
            <div className={cls.img}>
                <div {...wrapperProps}>
                    <img ref={ref} src={src} {...imgProps} />
                </div>
            </div>
            {!done && (
                <div className={cls.loader}>
                    <div className={cls.loaderContent}>
                        {children || <RiceSpinner />}
                    </div>
                </div>
            )}
        </div>
    );
}

export default LazyImg;