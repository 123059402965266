import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
    return {
        ProductMetrics: {
            display: 'block',
            fontSize: 18,
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            padding: theme.spacing(1, 0),
        },
        metric: {
            display: 'block',
            marginBottom: theme.spacing(3),
        }
    };
});