import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {

    const benchSize = 80;

    return {
        ProductImages: {
            position: 'relative',
            zIndex: 0,
        },
        stage: {
            display: 'block',
        },
        stageImg: {
            width: '100%',
            height: 'auto',
        },
        bench: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
        },
        benchImage: {
            border: '1px solid transparent',
            height: benchSize,
            width: benchSize,
            cursor: 'pointer',
            '& img': {
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center 75%',
            }
        },
        benchActive: {
            border: `1px solid ${theme.palette.primary.dark}`,
            '& img': {
                opacity: 0.5,
            }
        }
    };
});