import React from 'react';
import { Link } from 'gatsby';
import { useShopifyBuy } from 'components/context/ShopifyBuy';
import { Grid, Box, Typography, Hidden } from '@material-ui/core';
import ProductImages from 'components/shop/ProductImages';
import ProductMetrics from 'components/shop/ProductMetrics';
import ProductDetail from 'components/shop/ProductDetail';
import AddToCartForm from 'components/forms/AddToCartForm';
import RiceSpinner from 'components/spinner/RiceSpinner';
import ShopAlert from 'components/alerts/ShopAlert';
import makeStyles from './ShopProduct.css.js';

/*
    Product payload is built with ShopifyBuy API -- not graphql query
     - Get prices via variants (assume one variant)
*/
const ShopProduct = (props) => {

    const { handle } = props;

    const shopify = useShopifyBuy();

    const [pending, setPending] = React.useState(true);
    const [fetchError, setFetchError] = React.useState(null);
    const [product, setProduct] = React.useState(null);

    const fetchProduct = async () => {
        // do not fetch if product is already loaded or fetch is currently pending
        if (!!product) {
            return;
        }

        setPending(true);

        try {
            const product = await shopify.client.product.fetchByHandle(handle);
            // console.log('[Success] Fetch complete.', {product});
            setProduct(product);
            setFetchError(null);
        } catch (err) {
            console.error(`[Fail] Fetch failed with error: `, err);
            setFetchError(err);
        } finally {
            setPending(false);
        }
    }

    React.useEffect(() => {
        if (shopify.mounted) {
            fetchProduct();
        }
    }, [shopify.mounted]);

    const classes = makeStyles();

    return (
        <div className={classes.ShopProduct}>
            <div className={classes.productPageNav}>
                <div className={classes.navCrumb}>
                    <Link to="/shop">
                        <Hidden xsDown>
                            Fall / Winter 2021 Collection
                        </Hidden>
                        <Hidden smUp>
                            Shop
                        </Hidden>
                    </Link>
                </div>
                <div className={classes.navCrumb}>
                    {product ? product.title : handle ? handle.replace(/\-/g, ' - ') : null}
                </div>
            </div>
            {pending ? (
                <Box p={12} display="block">
                    <RiceSpinner size="8rem" />
                </Box>
            ) : fetchError ? (
                <ShopAlert type="alert">
                    Failed to load product data. Please{` `}
                    <a onClick={fetchProduct}>reload</a>
                    {` `}the page or{` `}
                    <Link to="/shop">return to the shop.</Link>
                </ShopAlert>
            ) : (
                <Grid container spacing={6} className={classes.productPageContainer}>
                    <Grid item xs={12} md={8}>
                        {product && (
                            <ProductImages images={product.images} />
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {product && (<>
                            <Typography component="h2" variant="button" className={classes.productTitle}>
                                {product && product.title}
                            </Typography>
                            <ProductMetrics product={product} />
                            <AddToCartForm product={product} />
                            <ProductDetail label="Product Details">
                                <article
                                    className={classes.productDescription}
                                    dangerouslySetInnerHTML={{__html: product.descriptionHtml}}
                                />
                            </ProductDetail>
                            <ProductDetail label="Shipping">
                                <article>
                                    <p>
                                        All domestic orders are shipped via UPS and all international
                                        orders are shipped via USPS or DHL. This item ships in 3 business
                                        days. All packages are insured and trackable. An email containing
                                        the tracking number will be sent to you when the order ships.
                                    </p>
                                    {/* <div className={classes.notice}>
                                        Please note that all orders placed from 6/25-7/4 will not be processed until 7/5. We apologize for any inconvenience. 
                                    </div> */}
                                </article>
                            </ProductDetail>
                            <ProductDetail label="Sales Policy">
                                <p>
                                    All sales are final. For more information, view our
                                    {` `}
                                    <Link to="/terms-of-use">
                                        <Box color="primary.dark" component="span">
                                            Shipping and Return policies
                                        </Box>
                                    </Link>
                                    .
                                </p>
                            </ProductDetail>
                        </>)}
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default ShopProduct;