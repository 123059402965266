import _ from 'lodash';
import React from 'react';
import { navigate } from 'gatsby';
import { useShopifyBuy } from 'components/context/ShopifyBuy';
import { TextField } from '@material-ui/core';
import FormButton from 'components/forms/FormButton';
import makeStyles from './AddToCartForm.css';
import makeFormStyles from './form.css';

const AddToCartForm = ({ product }) => {

    const shopify = useShopifyBuy();
    const lineItems = _.get(shopify, 'checkout.lineItems', []);

    const quantityRef = React.useRef();
    const [error, setError] = React.useState(null);

    const productAlreadyInCart = Boolean(typeof lineItems === 'object' && lineItems.find(item => item.variant.id === product.variants[0].id));
    const disabled = Boolean(!shopify.client || !product.availableForSale || shopify.pending || productAlreadyInCart);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (disabled) return;
        
        const quantity = parseInt(quantityRef.current ? quantityRef.current.value : 1);
        const invalidQuantity = (
          typeof quantity !== 'number' ||
          quantity === 0 ||
          quantity > 1
        );

        if (invalidQuantity) {
            setError(true);
        } else {
            // Add quantity to cart
            addToCart(quantity);
        }
    }

    const addToCart = async (quantity) => {
        await shopify.cart.add(product, quantity);
        // go to cart after adding item
        if (typeof window !== 'undefined') {
            navigate('/shop/cart');
        }
    }

    const renderButtonText = () => {
      if (product.availableForSale) {
        if (productAlreadyInCart) {
          return 'Already in Cart';
        } else {
          return 'Add to Cart';
        }
      } else {
        return 'Unavailable';
      }
    }

    const classes = makeStyles();
    const formClasses = makeFormStyles();

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className={classes.AddToCartForm}>
                {/* Hide quantity field for FW2022 launch
                <TextField
                    id="product-quantity"
                    inputRef={quantityRef}
                    label="Quantity"
                    name="quantity"
                    type="number"
                    variant="outlined"
                    required
                    className={formClasses.FormField}
                    size="small"
                    fullWidth
                    defaultValue={1}
                    error={error}
                    helperText={error ? 'Required' : null}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        type: 'number',
                        step: 1,
                    }}
                    disabled={disabled}
                />
                */}
                
                {/* Only 1 of each item in FW2022 shop */}
                <input type="hidden" id="product-quantity" name="quantity" value={1} />
                <FormButton
                    type="submit"
                    fullWidth
                    disabled={disabled}
                >
                    {renderButtonText()}
                </FormButton>
            </div>
        </form>
    );
}

export default AddToCartForm;