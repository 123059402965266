import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
    return {
        ProductDetail: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 14,
            textAlign: 'left',
        },
        control: {
            display: 'block',
            width: '100%',
            position: 'relative',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            fontSize: 12,
            lineHeight: 4,
            backgroundColor: 'transparent',
            textAlign: 'left',
            border: 'none',
            borderBottom: `1px solid ${theme.palette.primary.dark}`,
            padding: 0,
        },
        label: {
            fontSize: 'inherit',
            letterSpacing: '1px',
        },
        controlArrow: {
            position: 'absolute',
            right: 0,
            top: '50%',
            color: theme.palette.secondary.main,
            fontSize: 24,
            width: '1em',
            height: '1em',
            textAlign: 'center',
            transform: 'translateY(-50%)',
            '&:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: 8,
                height: 8,
                borderTop: '2px solid currentColor',
                borderRight: '2px solid currentColor',
                transform: 'translate(-50%, -50%) rotate(135deg)'
            },
        },
        controlOpen: {
            '& $controlArrow': {
                color: theme.palette.secondary.dark,
                transform: 'translateY(calc(4px - 50%))',
                '&:after': {
                    transform: 'translate(-50%, -50%) rotate(-45deg)',
                },
            },
        },
        collapseContainer: {
            display: 'block',
            width: '100%',
        },
        content: {
            display: 'block',
            width: '100%',
            padding: theme.spacing(2,0,4),
            textAlign: 'left',
        }
    };
});